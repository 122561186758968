import axios from 'axios';
import { ErrorMessage, Formik, Field, Form } from 'formik';
import { TextField } from 'formik-mui';
import * as Yup from 'yup';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button, Alert, Typography, Container, Box } from '@mui/material'
import protect3d from '../protect3d.png'

function ResetPassword(props) {

  const [alert, setAlert] = useState();
  const [search, setSearch] = useSearchParams();

  const initialValues = {
    password: "",
    passwordConfirmation: "",
  }

  const validationSchema = Yup.object({
    password: Yup.string().required("Required"),
    passwordConfirmation: Yup.string().oneOf([Yup.ref("password"), null], "Password must match").required("Required"),
  })

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    setAlert();

    const queryParams = new URLSearchParams(window.location.search)
    values.code = search.get('code');

    console.log(values)

    // live url: http://dashboard.protect3d.io
    // dev url: https://protect3d-admin-dev.herokuapp.com
    // local url: http://localhost:1337
    axios
      .post('https://dashboard.protect3d.io/auth/reset-password', values)
      .then(response => {
        const message = `Your password has been reset. Contact our team if you have trouble logging in.`;
        setAlert(['success', message]);

        console.log(response)

        resetForm();

        // setTimeout(() => { push('/auth/login') }, 5000);
      })
      .catch(error => {
        if ( !error.message ) {
          setAlert(['alert', "Something went wrong"])
        } else {
          console.log(error)
          setAlert(['alert', error.response.data.message[0].messages[0].message]);
          // const messages = error.response.data.message[0].messages;

          // const list = [];
          // messages.map((message,i) => {
          //     let item = "";
          //     if (i === 0) item += `<ul>`;

          //     item += `<li>${message.id}</li>`;

          //     if (i === messages.length - 1) item += `</ul>`
          //     list.push(item);
          // });

          // setAlert(['alert', list]);
        }
      })
      .finally(() => {
        setSubmitting(false);
      })
  }

  return (
    <div>
      <Container maxWidth="sm">
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          height='200px'>
            <img src={protect3d} display='block' margin-left='auto' margin-right='auto' width='400px'/>
        </Box>
        <Typography variant="h6">
        Reset Password
        </Typography>
        <hr />
        <Typography variant="body1">
          Please enter your new password in both of the fields below, then click "Reset Password". If you have any issues logging in after this, contact PROTECT3D to assist further.
        </Typography>
        <Box height='50px'/>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => onSubmit(values, {setSubmitting, resetForm })} >
            { ({ isSubmitting, isValid }) => (
              <Form>
                <div>
                  <div style={{paddingBottom:'10px'}}><label htmlFor="password">Password</label></div>
                  <Field component={TextField} type="password" id="password" name="password" placeholder="Password" />
                </div>

                <br/>

                <div>
                  <div style={{paddingBottom:'10px'}}><label htmlFor='passwordConfirmation'>Repeat Password</label></div>
                  <Field component={TextField} type='password' id='passwordConfirmation' name='passwordConfirmation' placeholder='Repeat password' />
                </div>

                <br />

                <Button
                  type='submit'
                  variant="contained"
                  disabled={!isValid}>
                  {!isSubmitting && 'Reset Password'}
                  {isSubmitting && 'Loading...'}
                </Button>
              </Form>
            )}
        </Formik>
        {(alert) && (
          <div style={{paddingTop:'20px'}}><Alert severity={ alert[0] === "success" ? "success" : "error"}>{alert[1]}</Alert></div>
        )}
      </Container>
    </div>
  )
  
}

export default ResetPassword;