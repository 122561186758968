import { Box, Container, Typography } from '@mui/material'

function Footer(props) {

  return (
    <div>
    <Container>
      <Box
        sx={{
        width: '100%',
        backgroundColor: 'lightgrey',
        position: 'absolute',
        left: '0',
        bottom: '0',
        right: '0',
        padding: '10px'
        }}>
        <Typography variant='subtitle2'>
          PROTECT3D | www.protect3d.io | Durham, NC
        </Typography>
      </Box>
    </Container>
    </div>
  )
}

export default Footer
