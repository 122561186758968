import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { TextField } from 'formik-mui';
import { useState } from 'react';
import { Button, LoadingButton, Alert, Typography, Container, Box } from '@mui/material'
import protect3d from '../protect3d.png';

import axios from 'axios';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';


function ForgotPassword(props) {

  // const [isValid, setValid] = useState(false)
  // const [isSubmitting, setSubmitting] = useState(false)
  const [alert, setAlert] = useState();

  const initialValues = {
    email: ""
  };

  const validationSchema = Yup.object({
    email: Yup.string().email("Insert a valid email").required("Required")
  });

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    setAlert();
    
    // live url: http://dashboard.protect3d.io
    // dev url: https://protect3d-admin-dev.herokuapp.com
    // local url: http://localhost:1337
    axios
      .post('https://dashboard.protect3d.io/auth/forgot-password', values)
      .then(response => {
        const message = 'Please check your email to reset your password.';
        setAlert(['success', message]);
        resetForm();
      })
      .catch(error => {
        if ( !error.response.data.message ) {
          setAlert(['alert', "Something went wrong"])
        } else {
          console.log(error)

          setAlert(['alert', error.response.data.message[0].messages[0].message]);
        }
      })
      .finally(() => {
        setSubmitting(false);
      })
  }



  return (
    <div>
    <Container maxWidth="sm">
      
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        height='200px'>
          <img src={protect3d} display='block' margin-left='auto' margin-right='auto' width='400px'/>
      </Box>
      <Typography variant="h6">
        Forgot Password
      </Typography>
      <hr />
      <Typography variant="body1">
        Forgot your password? No worries. Enter the email address that you use to login to the PROTECT3D app and click "Send Link". You will receive an email with instructions on how to reset your password.
      </Typography>
      <Box height='50px'/>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => onSubmit(values, { setSubmitting, resetForm})}>
          { ({isSubmitting, isValid }) => (
            <Form>
              <div>
                <div style={{paddingBottom:'10px'}} ><label htmlFor="email">Email</label></div>
                <Field component={TextField} type="email" id="email" name="email" placeholder="Email"/>
              </div>

              <br/>
              
              <Button
                type="submit"
                variant="contained"
                disabled={!isValid}>
                  {!isSubmitting && "Send link"}
                  {isSubmitting && "Loading..."}
              </Button>
            </Form>
          )}
      </Formik>
      {(alert) && (
        <div style={{paddingTop:'20px'}}><Alert severity={ alert[0] === "success" ? "success" : "error"}>{alert[1]}</Alert></div>
      )}
    </Container>
    </div>
  );
}

export default ForgotPassword;